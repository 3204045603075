import React, { useState } from "react";
import "./BurgerMenu.css";
import { Link } from "react-scroll";

const Navbar = () => {
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  return (
    <>
      <nav>
        <div className="burger-menu" onClick={updateMenu}>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
        </div>
      </nav>

      <div className={menu_class}>
        <div className="menu-active">
          <Link
            onClick={updateMenu}
            to="secondContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            QUE LOGRARÁS
          </Link>
          <Link
            onClick={updateMenu}
            to="thirdContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            NOSOTROS
          </Link>
          <Link
            onClick={updateMenu}
            to="fourthContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            CURSOS
          </Link>
          <Link
            onClick={updateMenu}
            to="sixthContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            TESTIMONIOS
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
