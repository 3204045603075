import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage.js";
import Tyc from "./components/TyC";
import Footer from "./components/Footer.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="tyc" element={<Tyc />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
