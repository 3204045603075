import React from "react";
import "./TyC.css";
import Navbar from "./Navbar.js";

const Tyc = () => {
  return (
    <>
      <Navbar isTyc={true} />
      <div className="tycBody">
        <span className="tycTitle">TÉRMINOS Y CONDICIONES DE USO</span>
        <div className="tycCard">
          <span className="tycCardTitle">Términos y condiciones</span>
          <span className="tycText">
            Política de términos y condiciones de cursos a distancia de
            Desarrollo Educativo, en adelante -La Compañía-.
          </span>
          <span className="tycText">
            1. Generalidades sobre los Términos de Servicio.
          </span>
          <span className="tycText">
            1.1. La Compañía posee y opera el sitio Desarrollo Educativo. Le
            ofrecemos la posibilidad de aprender a través de nuestros servicios
            en línea y de la funcionalidad del sitio, así como fuera de línea
            por medio de descargas disponibles en el sitio. El Servicio incluye
            el acceso a la plataforma la cual es administrada por la Compañía.
          </span>
          <span className="tycText">
            2. Permiso para utilizar el Servicio, reserva de derechos.
          </span>
          <span className="tycText">
            2.1. Al aceptar estos Términos de Servicio, se le concede a usted un
            derecho limitado, no exclusivo, intransferible y no sublicenciable,
            para entrar, acceder y utilizar el Servicio exclusivamente para uso
            personal y no comercial.
          </span>
          <span className="tycText">
            2.2. Todos los contenidos de este Sitio Web, incluyendo el texto,
            software, nombres, logotipos, marcas comerciales, marcas
            registradas, nombres comerciales, imágenes, fotografías, dibujos,
            música y videos son propiedad intelectual que está protegida por
            copyright.
          </span>
          <span className="tycText">
            2.3. Todos los derechos de uso son propiedad de, y están controlados
            por, el/los propietarios de este Sitio Web.
          </span>
          <span className="tycText">
            2.4. Los derechos de uso de todo aquello que descargue, cualquier
            software, incluyendo todos sus ficheros, las imágenes incorporadas o
            generadas por dicho software, así como todos los datos que incluyen,
            le son cedidas por este Sitio Web, o por los propietarios de los
            mismos, para su uso personal, no para fines comerciales.
          </span>
          <span className="tycText">3. Descripción del Servicio.</span>
          <span className="tycText">
            3.1. Su Cuenta e Información de Registro. Puede navegar, pre
            visualizar y realizar una búsqueda dentro del Servicio sin necesidad
            de abrir una Cuenta de Desarrollo Educativo ni de suministrar
            información de registro. Sin embargo, para cualquier otro uso del
            Servicio y el acceso al contenido de Desarrollo Educativo, deberá
            abrir una cuenta y facilitar la información de registro a la
            Compañía.
          </span>
          <span className="tycText">
            3.2. Acceso al Contenido de Desarrollo Educativo. Ud. puede acceder
            al contenido de la plataforma la cantidad de veces que lo desee
            siempre que se haya suscrito al Servicio y no se encuentre en
            incumplimiento de ningún término del contrato.
          </span>
          <span className="tycText">
            3.3. Términos adicionales del software y hardware. Tenga en cuenta
            que tal vez existan acuerdos de licencia del usuario final y
            términos de servicio por separado que deba aceptar para el hardware
            y el software que utilice en relación con el Servicio, o que pueda
            interactuar con el Servicio. Su negativa a aceptar los términos de
            dichos acuerdos limitaría su capacidad para utilizar plenamente el
            Servicio. Además, el Servicio sólo funciona con dispositivos
            compatibles.
          </span>
          <span className="tycText">
            4. Políticas Académicas de Desarrollo Educativo. Se considerará como
            Alumno Regular a todo alumno que se encuentre de forma activa en
            cualquiera de las instancias de cursada (1er, 2do o 3er
            cuatrimestre).
          </span>
          <span className="tycText">5. Cargos y Facturación.</span>
          <span className="tycText">
            5.1. Acuerdo de pago. Todos los usuarios del Servicio deben aceptar,
            en primer lugar, estos Términos de Servicio. Usted acepta pagar por
            todo el Contenido del curso que no se obtenga por medio de un código
            de promoción o que la Compañía no le haya ofrecido en forma
            gratuita.
          </span>
          <span className="tycText">
            5.2. Terminación. La Compañía se reserva el derecho a dar por
            terminada su Cuenta de Desarrollo Educativo y/o el acceso por parte
            de usted al Servicio si infringe los Términos de Servicio. Si se
            diera por terminada su membresía y suscripción(s) de Desarrollo
            Educativo al Servicio, no serán reembolsables las cuotas ni los
            cargos ya abonados.
          </span>
          <span className="tycText">
            5.3. Transacciones bancarias y con tarjetas de crédito. Durante el
            proceso de registro se le solicitará que proporcione información
            sobre su tarjeta de crédito o cuenta bancaria (su instrumento de
            pago). Como usuario del Servicio, usted acepta que se realicen
            cargos a su tarjeta de crédito o se debiten de su cuenta bancaria,
            las cuotas aplicables, así como cualquier impuesto sobre la venta y
            cualquier otro cargo en el que incurra en relación con el uso del
            Servicio. Salvo lo establecido en el presente documento, ninguna
            cuota ni cargo es reembolsable. En el caso de que utilice su tarjeta
            de crédito para hacer compras en el Servicio, nosotros podremos, a
            nuestra discreción, asentar los cargos en su tarjeta de crédito de
            forma individual o agregar sus cargos a otras compras que realice en
            el Servicio. En este sentido, Ud. está de acuerdo en pagar todos los
            montos que se causen en función a los Términos de Compra, y a su vez
            a mantener los fondos adecuados en su instrumento de pago para poder
            satisfacer los montos que pueda adeudar a la Compañía.
            Adicionalmente, usted es responsable de mantener la confidencialidad
            de su Cuenta de Desarrollo Educativo y será responsable de cualquier
            cargo en el que incurra cualquier otra persona a través de su cuenta
            de Desarrollo Educativo. Todos los cargos se facturarán al
            instrumento de pago que usted designe la primera vez que realice una
            compra o incurra en un cargo en el Servicio. Cualquier comisión
            bancaria o cargo reflejado en su instrumento de pago realizado por
            su institución financiera o crediticia, estarán bajo su
            responsabilidad. Si cambiara la información de su facturación, usted
            deberá actualizar dicha información poniéndose en contacto con
            inscripciones@desarrolloedu.com. Al proporcionar la información de
            su tarjeta de crédito o cuenta bancaria a la Compañía, usted
            reconoce y acepta que nosotros podemos archivar la información de su
            instrumento de pago hasta que cierre su Cuenta de Desarrollo
            Educativo y, a menos que usted solicite a Atención al Cliente de
            Desarrollo Educativo que se elimine la información de su tarjeta de
            crédito y/o cuenta bancaria, en cuyo caso ésta será eliminada. Al
            proporcionar la información de su instrumento de pago a la Compañía,
            usted conviene en que consultará los términos y condiciones que
            impone el emisor de su instrumento de pago en cuanto a los
            requisitos de notificación y limitaciones de la responsabilidad que
            le sean aplicables en caso de pérdida, robo o uso no autorizado de
            su instrumento de pago. Usted acepta que usted, y no Desarrollo
            Educativo o sus Afiliadas, será el único responsable del pago de
            todos los importes facturados a su tarjeta de crédito por terceros
            no autorizados. Usted acepta que no proporcionará información sobre
            ningún instrumento de pago que no haya sido expedido a su nombre.
          </span>
          <span className="tycText">
            5.4. Impuestos. Las compras en el Servicio pueden incluir el
            impuesto sobre ventas o el impuesto al valor agregado (cuando
            proceda) y dicho impuesto se basará en la mejor información
            disponible acerca de su dirección. En tales casos, se aplicará el
            tipo impositivo que esté en vigor en el momento en que se realicen
            las compras en el Servicio. Si cambia el tipo impositivo aplicable a
            las ventas antes de finalizar las compras correspondientes, se
            aplicará el nuevo tipo impositivo que esté en vigor en el momento de
            concretar la compra en el Servicio. Podrían aplicarse otras
            limitaciones y renuncias a responsabilidad sobre productos y
            servicios. Usted será el único responsable de pagar la totalidad de
            dichos impuestos.
          </span>
          <span className="tycText">
            5.5. Cargos por mora. La Compañía se reserva el derecho de imputar
            cargos por mora en el valor de las cuotas en el caso que usted
            genere el incumplimiento en los términos de pago expresados en el
            contrato.
          </span>
          <span className="tycText">
            5.6. Derecho al cambio de precios. Todos los precios relacionados
            con el Servicio están sujetos a cambios por la Compañía. La Compañía
            no ofrece una protección de precios o reembolsos en caso de una
            caída de precios o una oferta promocional. 6. Bajas o Reembolsos.
          </span>
          <span className="tycText">6. Bajas o Reembolsos.</span>
          <span className="tycText">
            6.1. Luego de transcurridos diez (10) días corridos desde la fecha
            de compra del Curso, los pagos o cargos realizados no serán
            reembolsables.
          </span>
          <span className="tycText">
            6.2. Dentro de los 10 días, en caso de solicitar devolución, el
            dinero será acreditado en la misma forma en la cual usted realizó la
            compra. Desarrollo Educativo efectuará el reintegro dentro de los
            TREINTA (30) días corridos de resuelto el reclamo.
          </span>
          <span className="tycText">
            6.3. Penalidad por extinción. Tras la extinción del presente
            contrato por parte del Alumno, el mismo deberá abonar al momento de
            la baja un monto igual a la siguiente cuota del plan de pagos
            acordado.
          </span>
          <span className="tycText">
            6.4. En caso de haber errores u cobros incorrectos, Desarrollo
            Educativo será responsable de su reembolso.
          </span>
          <span className="tycText">7. Promociones</span>
          <span className="tycText">
            7.1. La Compañía, otras entidades y determinados terceros pueden, de
            vez en cuando, colocar anuncios publicitarios o presentar ofertas y
            materiales promocionales en o a través del Servicio. Su
            participación en cualquier promoción u oferta está sujeta a las
            normas aplicables relacionadas con la promoción. Sus relaciones con
            otras entidades o terceros, así como su participación en promociones
            u ofertas de terceros que se anuncian o aparecen en el Servicio son
            exclusivamente entre usted y dicho tercero. Tenga en cuenta que debe
            tener al menos 13 años de edad para participar en cualquier
            promoción u oferta que tenga conexión o guarde relación de alguna
            manera con el Servicio. USTED ACEPTA QUE LA COMPAÑÍA NO SERÁ
            RESPONSABLE DE NINGÚN TIPO DE DAÑO O PÉRDIDA QUE SURJA COMO
            RESULTADO DE TALES RELACIONES O DE LA PRESENCIA DE DICHOS
            ANUNCIANTES EN EL SERVICIO.{" "}
          </span>
          <span className="tycText">
            8. Restricciones. El uso del Servicio, por parte de usted, está
            sujeto a las siguientes restricciones: 
          </span>
          <span className="tycText">
            8.1. La falta de autorización de cobro o la mora en los pagos
            habilitará a Desarrollo Educativo al inmediato bloqueo de la
            plataforma y acceso al material de estudio como así también al
            inicio de acciones extrajudiciales y judiciales tendientes al cobro
            de los montos adeudados.
          </span>
          <span className="tycText">
            8.2. Requisito de edad. Usted debe tener al menos 18 años de edad
            para convenir y aceptar estos Términos de Servicio en nombre propio.
            Si es menor de 18 años, su padre, madre o tutor legal debe aceptar
            estos Términos de Servicio y registrarse para el Servicio en nombre
            de usted. Si es menor de 13 años, se le solicita que no proporcione
            información alguna a la Compañía, ya sea mediante el uso del
            Servicio, la participación en alguna de las promociones, concursos o
            sorteos relacionados con el Servicio, o a través de cualquier otra
            actividad.
          </span>
          <span className="tycText">
            8.3. Conducta prohibida. Usted no utilizará el Servicio para
            transmitir, mostrar, ejecutar o de algún modo poner a disposición
            mensajes, contenidos o materiales (i) que sean ilegales, obscenos,
            amenazantes, masivos no solicitados o -spam-, difamatorios,
            invasores de la privacidad, o (ii) que violen o infrinjan derechos
            de autor, marcas registradas, patentes, secretos comerciales y otros
            derechos de propiedad intelectual, derechos de privacidad o
            publicidad, reglamentos o estatutos de comunicaciones, o
            cualesquiera otras leyes, incluyendo, sin limitación, las leyes
            sobre difamación, acoso, obscenidad y pornografía; (iii) que
            constituyan campañas políticas o solicitudes de venta o marketing o
            que contengan virus informáticos u otro código de computadora
            destinado a interferir con la funcionalidad de los sistemas de
            computadoras, o (iv) que de alguna manera perjudiquen a los menores.
            Usted conviene en no interrumpir ni intentar interrumpir la
            operación o el Servicio de ninguna manera. Durante el Curso, se
            espera que los estudiantes se comporten de una manera adecuada y
            respetuosa, teniendo en cuenta un alto estándar de educación y
            progresión de carrera para con las otras personas. Cualquier
            violación a lo aquí dispuesto, estará sujeta a la revisión y las
            acciones pertinentes que la Compañía decida adoptar, a su sola
            consideración e inclusive proceder al derecho de Terminar el
            Servicio de acuerdo a lo dispuesto en la Sección 9.3 del presente
            documento. Además, no podrá utilizar una dirección de correo
            electrónico falsa o de algún modo engañar a otros miembros en cuanto
            a su identidad o al origen de un mensaje o contenido.
          </span>
          <span className="tycText">
            8.4. Restricción sobre el uso relacionado con el Servicio. El
            Servicio incluye componentes de seguridad por lo que se aplican
            normas y políticas especiales. Usted no deberá intentar (ni apoyar
            los intentos de otros) eludir, aplicar ingeniería inversa,
            descifrar, descompilar, desmontar o de algún modo modificar, alterar
            o interferir con ningún aspecto del Servicio. Usted no podrá
            distribuir, intercambiar, modificar, vender o revender, o transmitir
            a cualquier otra persona cualquier parte del Servicio, incluyendo,
            sin limitación, cualquier texto, imagen o audio, para cualquier
            propósito empresarial, comercial o público. Usted conviene en no
            copiar, vender, distribuir o de algún modo transferir Contenido de
            Desarrollo Educativo, salvo del modo expresamente permitido en el
            presente.
          </span>
          <span className="tycText">
            8.5. Restricción sobre el uso relacionado con la Cuenta de
            Desarrollo Educativo. Usted solo puede abrir una cuenta de
            Desarrollo Educativo para usted mismo, y no para ninguna otra
            persona, con sujeción a las disposiciones de la sección anterior
            titulada -Requisito de Edad-. Usted acepta que no permitirá que
            otras personas utilicen la Información de Registro y/o la Cuenta de
            Desarrollo Educativo y acepta que usted es el único responsable de
            mantener la confidencialidad y seguridad de los mismos. Usted
            conviene en notificar inmediatamente a la Compañía cualquier uso no
            autorizado de su contraseña y/o Cuenta de Desarrollo Educativo,
            enviándonos un correo electrónico a inscripciones@desarrolloedu.com. 
            Si utiliza o abre una cuenta en el Servicio en nombre de una
            empresa, entidad u organización (colectivamente -Organización
            Suscriptora-), entonces usted declara y garantiza que es un
            representante autorizado de dicha Organización Suscriptora con
            autoridad para obligar a tal organización a estos Términos de
            Servicio, y acuerda obligarse por estos Términos de Servicio en
            nombre de dicha Organización Suscriptora.
          </span>
          <span className="tycText">
            8.6 Publicidad y/o promoción. Queda prohibido el uso del Servicio
            para la realización de campañas de publicidad explícitas o
            implícitas, ATL y BTL, gráficas, escritas o audiovisuales sobre
            productos y/o servicios que estén en conflicto con los intereses de
            Desarrollo Educativo. Usted no deberá utilizar el Servicio como
            medio para promocionar productos o servicios iguales o similares a
            los ofrecidos por Desarrollo Educativo, ya sean propios o de
            terceros.
          </span>
          <span className="tycText">
            9. Notificaciones especiales. Como usuario del Servicio, tenga en
            cuenta lo siguiente:
          </span>
          <span className="tycText">
            9.1. Riesgo de uso. Ni Desarrollo Educativo ni sus Filiales asumirán
            responsabilidad alguna y no serán responsables por ningún perjuicio
            o virus que provoque algún perjuicio a su computador u otra
            propiedad como consecuencia del acceso, uso, descarga o navegación
            por el Servicio.
          </span>
          <span className="tycText">
            9.2. Limitación de responsabilidad: Desarrollo Educativo no
            garantiza que el servicio sea compatible con cualquier computadora
            personal, reproductor de CD o DVD, grabador de CD o DVD, o
            dispositivos portátiles registrados. Usted es el único responsable
            de asegurarse de que sus sistemas y dispositivos funcionen correctamente con el
            servicio. Y a menos que Desarrollo Educativo le indique lo
            contrario, el uso de aplicaciones móviles se limita al dispositivo
            correspondiente y/o sistema operativo adecuado para acceder a la
            aplicación móvil. La empresa no acepta ninguna responsabilidad
            alguna por falta de disponibilidad de la aplicación o cualquier
            dificultad o incapacidad de descargar o acceder a contenido móvil o
            de cualquier otra falla de sistema o de comunicación que pudiere dar
            lugar a que la aplicación móvil no esté disponible.
          </span>
          <span className="tycText">
            9.3. Privacidad. Al registrarse en el Servicio, usted otorga su
            consentimiento para la recopilación y procesamiento de toda la
            información relacionada con el uso que usted hace del Servicio,
            incluyendo la Información de Registro. La Compañía recopila y maneja
            esta información de conformidad con los términos de la Política de
            Privacidad, que queda expresamente incorporada en estos Términos de
            Servicio mediante referencia.
          </span>
          <span className="tycText">
            9.4. Exactitud de la información. Usted declara y garantiza que toda
            la información (incluida la Información de Registro y las respuestas
            a los sondeos y encuestas) que proporciona a la Compañía es
            correcta, completa y vigente, y se compromete a actualizar dicha
            información cuando sea necesario. También admite que cualquier
            información proporcionada por usted a la Compañía no violará ninguna
            ley o reglamento, ni infringirá los derechos de terceros.
          </span>
          <span className="tycText">
            9.5. Certificación de residencia y capacidad para celebrar
            contratos. Al abrir una Cuenta de Desarrollo Educativo, usted
            declara y garantiza a la Compañía que reside en el territorio que ha
            indicado en su perfil y que tiene capacidad para celebrar contratos
            de conformidad con las leyes de su jurisdicción.
          </span>
          <span className="tycText">
            9.6. El derecho de la Compañía a cambiar estos Términos de Servicio.
            La Compañía se reserva el derecho, a su discreción, de cambiar,
            modificar, añadir o eliminar partes de estos Términos de Servicio en
            cualquier momento, sin previo aviso a usted. Todos los cambios
            entrarán en vigor de inmediato. En caso de algún cambio sustancial,
            haremos todos los esfuerzos comercialmente razonables para
            notificárselo y obtener su consentimiento antes de implementar
            dichos cambios. Le recomendamos que consulte estos Términos de
            Servicio en forma periódica para ver si se han registrado cambios.
            El uso continuado del Servicio por parte suya después de la
            publicación de dichos cambios implica la aceptación de los mismos.
          </span>
          <span className="tycText">10. Propiedad intelectual.</span>
          <span className="tycText">
            10.1. Secretos comerciales y materiales de propiedad exclusiva. El
            Servicio contiene y/o incorpora secretos comerciales, inventos
            patentados (y/o con patente pendiente) y/u otros materiales de
            propiedad exclusiva y propiedad intelectual de la Compañía y/o sus
            licenciadores. Todos los derechos de título y propiedad en el
            Servicio, incluyendo sin que signifique limitación alguna el
            contenido Desarrollo Educativo y cualquier copia relacionada con el
            Servicio y el contenido de Desarrollo Educativo, permanecen bajo la
            propiedad de la Compañía y sus licenciadores, según corresponda.
            Usted conviene en mantener de forma confidencial aquella información
            contenida en el Servicio que no sea de dominio público, y no revelar
            dicha información a terceros sin la aprobación previa por escrito de
            la Compañía.
          </span>
          <span className="tycText">
            10.2. Derechos de autor. El Contenido de Desarrollo Educativo y
            otras obras de autoría que se encuentran en el Servicio, o forman
            parte del mismo (colectivamente, el -Contenido-), están protegidos
            por las leyes aplicables de derechos de autor y los tratados
            internacionales de derechos de autor, así como por otras leyes y
            tratados de propiedad intelectual. El Contenido se licencia, no se
            vende. Usted no puede realizar copias no autorizadas o utilizar
            ninguna parte del Contenido, salvo como se especifica en este
            documento y de conformidad con la legislación aplicable. Todos los
            títulos y derechos de autor del Contenido y del Servicio (incluyendo
            sin que ello implique limitación alguna, imágenes, ilustraciones,
            gráficos, fotografías, animaciones, vídeo, audio, música, texto,
            guiones, -applets-, archivos, URLs, documentos y características
            interactivas incluidas o disponibles a través del Servicio), son
            propiedad de la Compañía o sus licenciadores. Usted acepta cumplir
            con todas las leyes aplicables sobre protección de derechos de autor
            en relación con el uso del Servicio y del Contenido por parte de
            usted. Además de lo estipulado en estos Términos de Servicio, la
            Compañía podrá adoptar medidas adecuadas para proteger los derechos
            de autor en el Contenido.
          </span>
          <span className="tycText">
            10.3. Acción por infracción de los derechos de propiedad
            intelectual. Si la Compañía recibe una notificación alegando que
            usted se ha involucrado en una conducta que viola cualquiera de los
            derechos de propiedad intelectual de la Compañía o de sus Afiliadas,
            o de un tercero, o razonablemente sospecha esto, la Compañía podrá
            suspender o dar por terminado el acceso suyo al Servicio, sin previo
            aviso. Si la Compañía actúa conforme a esta Sección, la misma no
            tendrá responsabilidad alguna frente a usted, incluso por cualquier
            cantidad que haya pagado previamente o cualquier crédito que tenga
            en el Servicio.
          </span>
          <span className="tycText">
            11. Ley aplicable y jurisdicción. Estos Términos se rigen y se 
            interpretan de conformidad con las leyes del pais Uruguay. 
            Usted y nosotros acordamos que los tribunales de Montevideo  
            tienen jurisdicción exclusiva para resolver cualquier disputa o 
            reclamo que surja de o en relación con estos Términos 
            (incluidas las disputas o reclamos no contractuales).
          </span>
          <span className="tycText">
            Si tienes preguntas o comentarios sobre los Términos y Condiciones
            del Servicio, no dude en escribirnos a la siguiente dirección:
            inscripciones@desarrolloedu.com
          </span>
        </div>
      </div>
    </>
  );
};

export default Tyc;
