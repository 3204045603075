import React from "react";
import logo from "../assets/logo.png";
import BurgerMenu from "./BurgerMenu.js";
import "./Navbar.css";
import { Link } from "react-scroll";
import { Link as ReactRouterLink } from "react-router-dom";

const Navbar = ({ isTyc }) => {
  return (
    <div className="header">
      {isTyc ? (
        <>
          <ReactRouterLink to="/" reloadDocument={true}>
            <img className="logoImg" src={logo} alt="" />
          </ReactRouterLink>
          <span className="tycNavbar">CONOCÉ NUESTRA PROPUESTA</span>
        </>
      ) : (
        <>
          <Link
            to="firstContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            <img className="logoImg" src={logo} alt="" />
          </Link>

          <BurgerMenu />
        </>
      )}
    </div>
  );
};

export default Navbar;
