import React, { useState } from "react";
import "./MainPage.css";
import Navbar from "./Navbar.js";
import checkboxImg from "../assets/checkbox.png";
import thirdContainerImg from "../assets/third-container-img.png";
import {
  itemsList,
  listadoCursos,
  testimoniosAlumnos,
  selectCursos,
} from "../utils.js";
import marceAvatar from "../assets/marce-avatar.png";
import carlosAvatar from "../assets/carlos-avatar.png";
import FiveStars from "./FiveStars";
import Select from "react-select";
import axios from "axios";
import Alerta from "./Alerta";
import { Link } from "react-scroll";

function App() {
  const [formInfo, setFormInfo] = useState({
    nombreCompleto: "",
    email: "",
    whatsapp: "",
    idCurso: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setFormInfo({
      ...formInfo,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    axios
      .post(
        "https://api-cron.axontraining.com/desarrollo-educativo/contacto",
        formInfo
      )
      .then((res) => {
        if (res.data.status === "Error") {
          setError(true);
          setShowModal(true);
        } else {
          setShowModal(true);
        }
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setError(false);
  };

  return (
    <>
      <Navbar />
      <div className="app">
        <div className="body">
          {showModal && <Alerta closeModal={closeModal} error={error} />}
          <div className="firstContainer" id="firstContainer">
            <div className="firstContainerTextWrap">
              <span className="firstContainerTitle">
                CURSOS PARA TU DESARROLLO
              </span>
              <span className="firstContainerText">
                Cursos diseñados para que descubras tu verdadero potencial y
                logres ser la mejor versión de ti mismo.
              </span>
            </div>
            <div className="form">
              <span className="formTitle">
                ¡Estás a un paso de transformar tu vida!
              </span>
              <label>Nombre completo</label>
              <input
                placeholder="Ingresa tu nombre completo..."
                name="nombreCompleto"
                onChange={(e) => handleInputChange(e)}
                defaultValue={formInfo.nombreCompleto}
                type="text"
                required
              />
              <label>Correo electrónico</label>
              <input
                placeholder="Ingresa tu correo electrónico..."
                name="email"
                onChange={(e) => handleInputChange(e)}
                defaultValue={formInfo.email}
                type="email"
                required
              />
              <label>WhatsApp</label>
              <input
                placeholder="Ingresa tu WhatsApp..."
                name="whatsapp"
                onChange={(e) => handleInputChange(e)}
                defaultValue={formInfo.whatsapp}
                type="number"
                required
              />
              <label>Curso de Interés</label>
              <div style={{ width: "100%" }}>
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: "100%",
                      borderRadius: "10px",
                      height: "34px",
                      marginBottom: "15px",
                      alignContent: "center",
                    }),
                  }}
                  defaultValue={formInfo.idCurso ?? ""}
                  placeholder="Nombre Curso"
                  options={selectCursos}
                  onChange={(item) => {
                    setFormInfo({ ...formInfo, idCurso: item.value });
                  }}
                />
              </div>
              <button className="formButton" onClick={() => handleSubmit()}>
                QUIERO SABER MÁS »
              </button>
            </div>
          </div>
          <div className="secondContainer" id="secondContainer">
            <span className="secondContainerTitle">
              ¿QUÉ LOGRARÁS CON ESTOS CURSOS?
            </span>
            <div className="secondContainerItems">
              {" "}
              {itemsList.map((item, i) => {
                return (
                  <div className="itemsContainer" key={i}>
                    <div>
                      <img className="checkbox" src={checkboxImg} alt="" />
                    </div>
                    <div>
                      <span>{item.description}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="thirdContainer" id="thirdContainer">
            <img className="thirdContainerImg" src={thirdContainerImg} alt="" />
            <div className="thirdContainerItems">
              <span className="thirdContainerTitle">
                ¿POR QUÉ DEBERIAS ESTUDIAR CON NOSOTROS?
              </span>
              <span className="thirdContainerText">
                Si eres una persona que le gustan los desafíos, que busca
                superarse constantemente y quiere aprender de una manera
                didáctica, divertida y a sus tiempos, estudiar en desarrollo
                educativo es ideal para ti.
              </span>
              <span
                className="thirdContainerText"
                style={{ marginBottom: "95px" }}
              >
                En nuestra institución podrás aprender de forma 100% online, con
                clases en vivo todas las semanas y tendrás un grupo de docentes
                especializado para acompañarte a lo largo de toda la cursada,
                ¡Aprender nunca fue tan fácil!
              </span>
            </div>
          </div>
          <div className="fourthContainer" id="fourthContainer">
            <span className="fourthContainerTitle">LISTADO DE CURSOS</span>
            <div className="fourthContainerGrid">
              {listadoCursos.map((item, i) => {
                return (
                  <div className="fourthContainerItems" key={i}>
                    <span className="itemsCardTitle">{item.title}</span>
                    <span className="itemsCardText">{item.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="fifthContainer">
            <span className="fifthContainerTitle">DOCENTES CALIFICADOS</span>
            <div className="fifthContainerItems">
              <div className="avatarContainer">
                <img src={marceAvatar} alt="" />
                <span className="fifthContainerNames">Marcelo Vinzon</span>
              </div>
              <div className="avatarContainer">
                <img src={carlosAvatar} alt="" />
                <span className="fifthContainerNames">Carlos Da Cunha</span>
              </div>
            </div>
          </div>
          <div className="sixthContainer" id="sixthContainer">
            <span className="sixthContainerTitle">
              TESTIMONIOS DE NUESTROS ALUMNOS{" "}
            </span>
            <div className="sixthContainerGrid">
              {testimoniosAlumnos.map((item, i) => {
                return (
                  <div className="sixthContainerCard" key={i}>
                    <div className="alumnosCardTitle">
                      <img className="alumnosImg" src={item.img} alt="" />
                      <div className="alumnosName">
                        <span className="sixthContainerCardName">
                          {item.name}
                        </span>
                        <FiveStars />
                      </div>
                    </div>
                    <span className="sixthContainerCardText">{item.text}</span>
                  </div>
                );
              })}
            </div>
            <div className="ofertasCard">
              <span className="ofertasTitle">
                ¡Ofertas por tiempo limitado!
              </span>
              <Link
                to="firstContainer"
                spy={true}
                smooth={true}
                offset={-60}
                duration={500}
              >
                <button className="ofertasButton">QUIERO SABER MÁS »</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
