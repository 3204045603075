import React from "react";
import footerLogo from "../assets/footer-logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <img className="footerImg" src={footerLogo} alt="" />
      <span>inscripciones@desarrolloedu.com</span>
      <span>® Desarrollo Educativo 2023.</span>
      <a style={{ textDecoration: "underline", color: "inherit" }} href="/tyc">
        Términos y Condiciones.
      </a>
      <span>Derechos reservados.</span>
    </div>
  );
};

export default Footer;
