import React from "react";
import "./Alerta.css";
import modalLogo from "../assets/footer-logo.png";

const Alerta = ({ closeModal, error }) => {
  return (
    <div className="alertaContainer">
      <div className="alertaContent">
        <img src={modalLogo} alt="" className="modalImg" />
        {error ? (
          <>
            {" "}
            <span className="modalTitle">¡Solicitud inválida!</span>
            <span className="modalText">
              Por favor verifique nuevamente los campos a completar e inténtelo
              de nuevo.
            </span>
          </>
        ) : (
          <>
            <span className="modalTitle">¡Solicitud registrada!</span>
            <span className="modalText">
              Hemos registrado tus datos correctamente para inscribirte a los
              cursos de Desarrollo Educativo. Un asesor estará contactándose a
              la brevedad.
            </span>
          </>
        )}

        <span onClick={closeModal} className="modalCross">
          X
        </span>
        <button onClick={closeModal} className="modalButton">
          VOLVER
        </button>
      </div>
    </div>
  );
};

export default Alerta;
