import React from "react";
import star from "../assets/star.png";

const FiveStars = () => {
  return (
    <div style={{display: "flex"}}>
      <img className="starImg" src={star} alt="" />
      <img className="starImg" src={star} alt="" />
      <img className="starImg" src={star} alt="" />
      <img className="starImg" src={star} alt="" />
      <img className="starImg" src={star} alt="" />
    </div>
  );
};

export default FiveStars;
